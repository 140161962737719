import { ChannelSensorTypes, Sensor, SensorType } from '@services/sensors'
import {
    DataQuery,
    SensorDataRequest,
    getPollingInterval,
    mapData,
} from '@services/data'
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { EuiButton, EuiEmptyPrompt, EuiLink, EuiTitle } from '@elastic/eui'
import {
    useFetchNetworkSensorDataQuery,
    useFetchOptionsQuery,
} from '@services/api'

import { CUSTOM_CHART_FETCH_TYPES } from '@services/network'
import HttpChart from '../NetworkChart/HttpChart'
import { LoadingSpinner } from '@components/layout'
import { Option } from '@services/options'
import PingChart from '../NetworkChart/PingChart'
import SnmpProcessorChart from '../NetworkChart/SnmpProcessorChart'
import SnmpTrafficChart from '../NetworkChart/SnmpTrafficChart'
import SshDiskChart from '../NetworkChart/SshDiskChart'
import SshMemoryChart from '../NetworkChart/SshMemoryChart'
import SshProcessorChart from '../NetworkChart/SshProcessorChart'
import SshServiceChart from '../NetworkChart/SshServiceChart'
import WmiDiskChart from '../NetworkChart/WmiDiskChart'
import WmiMemoryChart from '../NetworkChart/WmiMemoryChart'
import WmiProcessorChart from '../NetworkChart/WmiProcessorChart'
import WmiServiceChart from '../NetworkChart/WmiServiceChart'
import { useTranslation } from 'react-i18next'

type NetworkViewerProps = {
    sensor?: Sensor
    query: DataQuery
    onUpdate?: (lastUpdatedTimestamp: number) => void
}

const chartRenderer = (
    sensorId: Sensor['id'],
    type: SensorType | undefined,
    data: any,
    query: DataQuery,
    setQuery: Dispatch<SetStateAction<SensorDataRequest>>,
    options: Option[] | undefined
) => {
    if (!type) return null
    switch (type) {
        case SensorType.WmiProcessor:
            return (
                <WmiProcessorChart
                    data={data}
                    query={query}
                    setQuery={setQuery}
                />
            )
        case SensorType.WmiMemory:
            return (
                <WmiMemoryChart data={data} query={query} setQuery={setQuery} />
            )
        case SensorType.WmiDisk:
            return (
                <WmiDiskChart
                    data={data}
                    sensorId={sensorId}
                    options={options}
                    query={query}
                    setQuery={setQuery}
                />
            )
        case SensorType.WmiService:
            return (
                <WmiServiceChart
                    data={data}
                    query={query}
                    setQuery={setQuery}
                />
            )
        case SensorType.Ping:
            return <PingChart sensorId={sensorId} data={data} query={query} setQuery={setQuery} />
        case SensorType.Http:
            return <HttpChart data={data} query={query} setQuery={setQuery} />
        case SensorType.SshDisk:
            return (
                <SshDiskChart
                    data={data}
                    sensorId={sensorId}
                    options={options}
                    query={query}
                    setQuery={setQuery}
                />
            )
        case SensorType.SshMemory:
            return (
                <SshMemoryChart data={data} query={query} setQuery={setQuery} />
            )
        case SensorType.SshService:
            return (
                <SshServiceChart
                    data={data}
                    query={query}
                    setQuery={setQuery}
                />
            )
        case SensorType.SshProcessor:
            return (
                <SshProcessorChart
                    data={data}
                    query={query}
                    setQuery={setQuery}
                />
            )
        case SensorType.SnmpProcessor:
            return (
                <SnmpProcessorChart
                    data={data}
                    query={query}
                    setQuery={setQuery}
                />
            )
        case SensorType.SnmpTraffic:
            return (
                <SnmpTrafficChart
                    data={data}
                    query={query}
                    setQuery={setQuery}
                    sensorId={sensorId}
                />
            )
        default:
            console.error(`Unsupported chart for sensor type: ${type}`)
            return null
    }
}

const NetworkViewer = (props: NetworkViewerProps) => {
    const { t } = useTranslation(['common', 'network'])

    const isCustomChart =
        props.sensor && CUSTOM_CHART_FETCH_TYPES.includes(props.sensor.type)

    // Data fetching
    const [query, setQuery] = useState<SensorDataRequest>({ sensor: 0 })
    const { data, isLoading, isFetching, refetch, fulfilledTimeStamp } =
        useFetchNetworkSensorDataQuery(query, {
            skip: query.sensor === 0 || isCustomChart,
            pollingInterval: getPollingInterval(query.query),
        })

    const { data: optionsData } = useFetchOptionsQuery(
        { source: 'sensor', id: query.sensor },
        {
            skip:
                !props.sensor?.type ||
                ChannelSensorTypes.indexOf(props.sensor.type) < 0,
        }
    )

    const chartData = useMemo(() => mapData(data), [data])
    const isEmpty = !isCustomChart && chartData.length === 0

    const renderChart = useCallback(
        (type: SensorType | undefined, data: any, query: DataQuery) =>
            chartRenderer(
                props.sensor?.id || 0,
                type,
                data,
                query,
                setQuery,
                optionsData
            ),
        [data, props.sensor, props.query, optionsData, query.query]
    )

    useEffect(() => {
        setQuery({
            sensor: props.sensor?.id ?? 0,
            query: props.query,
        })
    }, [props.sensor, props.query])

    useEffect(() => {
        if (props.onUpdate) {
            props.onUpdate(fulfilledTimeStamp ?? 0)
        }
    }, [fulfilledTimeStamp])

    return (
        <LoadingSpinner loading={isLoading}>
            <>
                {!isEmpty &&
                    renderChart(
                        props.sensor?.type,
                        chartData,
                        query.query || props.query
                    )}

                {isEmpty && (
                    <EuiEmptyPrompt
                        iconType="searchProfilerApp"
                        title={<h2>{t('network:data_not_found')}</h2>}
                        color={'transparent'}
                        body={<p>{t('network:data_not_found_description')}</p>}
                        actions={
                            <EuiButton
                                color="primary"
                                isLoading={isFetching || isLoading}
                                onClick={() => refetch()}
                            >
                                {t('common:refresh')}
                            </EuiButton>
                        }
                        footer={
                            <>
                                <EuiTitle size="xxs">
                                    <h3>More useful links:</h3>
                                </EuiTitle>
                                <EuiLink href="/devices" target="_blank">
                                    Devices
                                </EuiLink>
                                <br />
                                <EuiLink href="/sensors" target="_blank">
                                    Sensors
                                </EuiLink>
                            </>
                        }
                    />
                )}
            </>
        </LoadingSpinner>
    )
}

export default NetworkViewer
